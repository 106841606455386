<template>
  <NotificationBase
    :notification
    :icon="{ name: 'id-card-clip-solid' }"
    :compact
  >
    <template #subject>
      {{ t('verification.verificationTitle') }}
    </template>
    <i18n-t
      v-if="type === 'actionLiveness'"
      keypath="notifications.body.biometricTest"
      tag="p"
    >
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>
    <i18n-t v-else keypath="notifications.body.verificationNeed" tag="p">
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>
    <StButton
      class="button"
      size="s"
      :label="t('verification.passVerification')"
      :to="{ query: { modal: 'sumSubVerification' } }"
    />
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { VerificationNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: VerificationNotification
  compact?: boolean
}>()

const { t } = useI18n()
const { type } = toRefs(notification.data)
const level = computed(() => {
  switch (type.value) {
    case 'actionSourceOfFunds':
      return t('dashboard.verificationLevel', { level: 3 })
    case 'level2':
      return t('dashboard.verificationLevel', { level: 2 })
    default:
      return t('dashboard.verificationLevel', { level: 1 })
  }
})
</script>

<style scoped>
.button {
  width: 100%;
  margin-top: var(--spacing-150);
}
</style>
